// Placeholder to allow defining custom styles that override everything else.
// (Use `_sass/minima/custom-variables.scss` to override variable defaults)

@import url('https://fonts.googleapis.com/css?family=Philosopher&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Courgette&display=swap');

:root {
  --navbar-height: 60px; /* Set your navbar height here */
}

// .site-nav {
.site-header {
  font-family: Courgette;
  font-size: 22px;
}
.site-footer {
  font-family: Philosopher;
}

.page-content {
  font-family: Philosopher;
  font-size: 20px;
}

h1 {
  font-family: Courgette;
  font-weight: bold;
}

#photo-ravs {
  border-radius: 50%;
}

p {
  text-align: justify;
}

/* Add this to your assets/main.scss file */
.tag-cloud {
  margin: 1em 0;
  text-align: center;
  line-height: 1.2;
}

.tag-cloud-item {
  display: inline-block;
  margin: 0.15em;
  padding: 0.2em 0.4em;
  text-decoration: none;
  transition: all 0.2s ease;
}

.tag-cloud-item:hover {
  text-decoration: none;
  opacity: 0.7;
}

/* More gradual size progression */
.tag-cloud-item[data-weight="1"] { font-size: 0.9em; }
.tag-cloud-item[data-weight="2"] { font-size: 1.1em; }
.tag-cloud-item[data-weight="3"] { font-size: 1.3em; }
.tag-cloud-item[data-weight="4"] { font-size: 1.5em; }
.tag-cloud-item[data-weight="5"] { font-size: 1.7em; }

.tag-count {
  font-size: 0.8em;
  color: #6c757d;
}

/* Adjust the width of the Desktop Screen */ 
.wrapper {
  max-width: 900px; /* Adjust this value to your desired width */
  margin: 0 auto; /* Center the content */
}

@media screen and (min-width: 800px) {
  .page-content {
    padding: 20px;
    padding-top: calc(20px + var(--navbar-height));
  }
}
